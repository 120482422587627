import { parse } from 'cookie';

export interface PostSharedProjectTrackingParams {
  projectId: string;
  body: {
    demo: {
      fingerprint: string;
      permanent_cookie_id: string;
      current_cookie_id: string;
      tracking: string;
      referrer: string | null;
      originalReferrer: string | null;
    };
    link_value: string;
  };
  baseUrl: string;
}

export const postSharedProjectTracking = ({
  projectId,
  baseUrl,
  body,
}: PostSharedProjectTrackingParams) => {
  const { token } = parse(document.cookie) as { token: string };
  return fetch(`${baseUrl}shared/projects/${projectId}/tracking`, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }).catch((error) => {
    console.error(`Analytics network request failed: ${error}`);
  });
};
