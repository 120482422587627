import { nanoid } from 'nanoid';
import { ParsedQs } from 'qs';
import omit from 'lodash-es/omit';
import { emailRegex } from 'shared-components/src/features/ui/ui.constants';
import {
  cookiesStorageWithFallback,
  localStorageWithFallback,
  sessionStorageWithFallback,
} from 'shared-components/src/utils/storageWithFallback';
import parseJSON from 'shared-components/src/utils/parseJSON';
import cookieStorageWithFallback from 'shared-components/src/utils/storageWithFallback/cookiesStorageWithFallback';

import {
  CrossOriginSessionEvent,
  ExtUrlTarget,
  PublicDemoLead,
  PublicDemoLeadSource,
} from './PublicDemoPage.types';
import {
  AnalyticsSessionEventValue,
  CrossOriginSessionEventEvent,
  CrossOriginSessionEventMessage,
  LeadSessionStorageKey,
  ThirdPartyAnalyticsEvent,
} from './PublicDemoPage.constants';
import { getSearchParams } from 'utils/getSearchParams';
import {
  PASSCODE_STORAGE_PREFIX,
  REQUIRED_EMAIL_STORAGE_PREFIX,
} from './PublicDemoPage.machine';
import {
  postSharedProjectEvents,
  PostSharedProjectEventsParams,
} from './api/events.api';
import {
  postSharedProjectTracking,
  PostSharedProjectTrackingParams,
} from './api/tracking.api';

const QUERY_PARAMS = ['hide_all', 'hide_steps', 'hide_forms'] as const; // see: https://storylane.atlassian.net/wiki/spaces/ENGINEERIN/pages/393234/Public+Demo+URL+search+params

interface SessionState {
  [AnalyticsSessionEventValue.Opened]?: boolean;
}

interface SessionRecord {
  id: string;
  state: SessionState;
}

/**
 * Initialize session data in sessionStorage if doesn't exist
 */
export function initSessionIfNeeded(projectId: string): void {
  const jsonSessionData =
    sessionStorageWithFallback.getItem(`sid_${projectId}`) || '';

  const sessionData = parseJSON(jsonSessionData);

  if (!sessionData) {
    sessionStorageWithFallback.setItem(
      `sid_${projectId}`,
      JSON.stringify(generateSessionData())
    );
  }
}

export function getCookiesPasscode(demoId: string) {
  return decodeURIComponent(
    cookiesStorageWithFallback.getItem(`${PASSCODE_STORAGE_PREFIX}${demoId}`) ??
      ''
  );
}

export function setCookiesPasscode(demoId: string, hashedPassword: string) {
  cookiesStorageWithFallback.setItem(
    `${PASSCODE_STORAGE_PREFIX}${demoId}`,
    encodeURIComponent(hashedPassword),
    {
      secure: process.env.NODE_ENV === 'production',
    }
  );
}

export function getCookiesEmailRequired(demoId: string) {
  return decodeURIComponent(
    cookiesStorageWithFallback.getItem(
      `${REQUIRED_EMAIL_STORAGE_PREFIX}${demoId}`
    ) || ''
  );
}

export function getCookiesEmailRequiredLead(
  demoId: string
): PublicDemoLead | null {
  const email = getCookiesEmailRequired(demoId);
  return email
    ? {
        email,
        source: PublicDemoLeadSource.RequiredEmail,
      }
    : null;
}

export function setCookiesEmailRequired(demoId: string, email: string) {
  cookiesStorageWithFallback.setItem(
    `${REQUIRED_EMAIL_STORAGE_PREFIX}${demoId}`,
    encodeURIComponent(email),
    {
      secure: process.env.NODE_ENV === 'production',
    }
  );
}

/**
 * retrieve lead from sessionStorage
 * @description
 * if issue with lead data, return null
 * @see https://storylane.atlassian.net/wiki/spaces/ENGINEERIN/pages/393227/Public+Demo#Lead
 * @returns {PublicDemoLead | null}
 */
export function getCookiesSessionLead(demoId: string): PublicDemoLead | null {
  try {
    const jsonSessionData =
      cookieStorageWithFallback.getItem(LeadSessionStorageKey + demoId) || '';

    return parseJSON(jsonSessionData) || null;
  } catch (error) {
    return null;
  }
}

/**
 * set lead to sessionStorage
 * @see https://storylane.atlassian.net/wiki/spaces/ENGINEERIN/pages/393227/Public+Demo#Lead
 */
export function setCookiesSessionLead(
  lead: PublicDemoLead,
  demoId: string,
  cookiesOptions?: Parameters<typeof cookieStorageWithFallback.setItem>[2]
): void {
  cookieStorageWithFallback.setItem(
    LeadSessionStorageKey + demoId,
    JSON.stringify(lead),
    cookiesOptions
  );
}

/**
 * Retrieve lead data from url search params
 * @see https://storylane.atlassian.net/wiki/spaces/ENGINEERIN/pages/393227/Public+Demo#Lead
 * @returns
 */
export function getQueryLead(path: string): PublicDemoLead | null {
  const query = getSearchParams(path);

  const leadEmail = query.get('email');
  const leadUserId = query.get('user_id');

  const isValidEmail = leadEmail && emailRegex.test(leadEmail);

  if (isValidEmail || leadUserId) {
    return {
      /**
       * @see {@link https://storylane.atlassian.net/wiki/spaces/ENGINEERIN/pages/393227/Public+Demo#Lead-source Docs}
       * @see {@link https://storylane.atlassian.net/browse/STORY-3080 Jira task}
       */
      source: PublicDemoLeadSource.UrlParams,
      email: leadEmail ?? undefined,
      externalId: leadUserId ?? undefined,
      name: query.get('name') ?? undefined,
    };
  }

  return null;
}

/**
 * Get fingerprint
 * @see https://storylane.atlassian.net/wiki/spaces/ENGINEERIN/pages/393227/Public+Demo#Visitor
 */
export function getFingerPrint(): string {
  const jsonFP = localStorageWithFallback.getItem('fp_id') || '';

  let fp = parseJSON(jsonFP);

  if (!fp) {
    fp = nanoid();
    localStorageWithFallback.setItem('fp_id', JSON.stringify(fp));
  }

  return fp;
}

/**
 * retrieve session data from sessionStorage
 * @see https://storylane.atlassian.net/wiki/spaces/ENGINEERIN/pages/393227/Public+Demo#Visitor
 */
export function getSessionData(projectId: string): SessionRecord {
  const jsonSessionData =
    sessionStorageWithFallback.getItem(`sid_${projectId}`) || '';

  let sessionData = parseJSON(jsonSessionData);

  if (!sessionData) {
    sessionData = generateSessionData();
    sessionStorageWithFallback.setItem(
      `sid_${projectId}`,
      JSON.stringify(sessionData)
    );
  }

  return sessionData;
}

/**
 * set/update session events data in sessionStorage
 * @see https://storylane.atlassian.net/wiki/spaces/ENGINEERIN/pages/393227/Public+Demo#Visitor
 */
export function setSessionData(
  projectId: string,
  sessionState: SessionState
): void {
  const jsonSessionData =
    sessionStorageWithFallback.getItem(`sid_${projectId}`) || '';

  const sessionData: SessionRecord =
    parseJSON(jsonSessionData) || generateSessionData();

  if (sessionState[AnalyticsSessionEventValue.Opened]) {
    sessionData.state[AnalyticsSessionEventValue.Opened] = true;
  }

  sessionStorageWithFallback.setItem(
    `sid_${projectId}`,
    JSON.stringify(sessionData)
  );
}

/**
 * Retrieve `eternal_id` from localStorage.
 * It is generated on the first visit; no-expiration date; stored in localStorage.
 *
 * @see https://storylane.atlassian.net/wiki/spaces/ENGINEERIN/pages/393227/Public+Demo#Visitor
 */
export function getEternalId(): {
  id: string;
} {
  let data = parseJSON(localStorageWithFallback.getItem('eid') || '');

  if (!data) {
    data = generateEternalData();
    localStorageWithFallback.setItem('eid', JSON.stringify(data));
  }

  return {
    id: data.id,
  };
}

/**
 * Generates eternal data initial value
 */
function generateEternalData() {
  return {
    id: nanoid(),
  };
}

/**
 * Generate session initial value.
 */
function generateSessionData(): SessionRecord {
  return {
    id: nanoid(),
    state: {
      opened: false,
    },
  };
}

interface SendTrackingDataProps {
  companyId: string;
  projectId: string;
  demoId: string;
  tracking: string;
  referrer: string | null;
  originalReferrer: string | null;
  baseUrl: string;
}

export async function sendTrackingData({
  projectId,
  demoId,
  tracking,
  referrer,
  originalReferrer,
  baseUrl,
}: SendTrackingDataProps): Promise<void> {
  // check session cookie
  const eternalIds = getEternalId();
  const sessionData = getSessionData(demoId);

  const payload: PostSharedProjectTrackingParams['body'] = {
    demo: {
      fingerprint: getFingerPrint(),
      permanent_cookie_id: eternalIds.id,
      current_cookie_id: sessionData.id,
      tracking,
      referrer,
      originalReferrer,
    },
    link_value: demoId,
  };

  postSharedProjectTracking({
    projectId,
    baseUrl,
    body: payload,
  });
}

export interface SendVisitDataProps {
  projectId: string;
  demoId: string;
  companyId: string;
  event: AnalyticsSessionEventValue;
  extUrl?: string;
  targetId?: string;
  baseUrl: string;
  query?: ParsedQs;
  clientTracking?: Record<string, string>;
  hostUrl?: string;
  urlQuery?: string;
  lead?: PublicDemoLead;
  userId?: string;
}

export async function sendVisitData({
  projectId,
  demoId,
  event,
  extUrl,
  targetId,
  query = {},
  clientTracking,
  hostUrl,
  urlQuery,
  baseUrl,
  userId,
  lead,
}: SendVisitDataProps): Promise<void> {
  // check session cookie
  const eternalIds = getEternalId();
  const sessionData = getSessionData(demoId);

  const sessionLead = getCookiesSessionLead(demoId);

  const slToken = query['sl_token'] as string | undefined;

  const hubCookieId = query['hub_cookie_id'] as string | undefined;

  const payload: PostSharedProjectEventsParams['body'] = {
    demo: {
      fingerprint: getFingerPrint(),
      permanent_cookie_id: eternalIds.id,
      current_cookie_id: sessionData.id,
      event,
      view_options: {},
      event_options: {},
      user_id: userId,
      lead,
      hub_cookie_id: hubCookieId,
    },
    link_value: demoId,
    host_url: hostUrl,
    url_query: urlQuery || null,
    lead_info: sessionLead,
    sl_token: slToken,
  };

  if (query) {
    QUERY_PARAMS.forEach((key) => {
      if (key in query) {
        payload.demo.view_options[key] = query[key] === 'true';
      }
    });
  }

  if (targetId) {
    payload.demo.target_id = targetId;
  }

  if (extUrl) {
    payload.demo.event_options.extUrl = extUrl;
  }

  if (clientTracking) {
    payload.demo.client_tracking = clientTracking;
  }

  postSharedProjectEvents({
    projectId,
    body: payload,
    baseUrl,
  }).then((res) => {
    if (payload.demo.event === AnalyticsSessionEventValue.Opened) {
      setSessionData(demoId, {
        [AnalyticsSessionEventValue.Opened]: true,
      });
    }
    return res;
  });
}

export const sendLeadData = async (args: {
  leadData: PublicDemoLead;
  projectId: string;
  demoId: string;
  companyId: string;
  onLeadCreate: () => void;
  baseUrl: string;
}): Promise<unknown> => {
  const { demoId, projectId, leadData, companyId, onLeadCreate, baseUrl } =
    args;

  try {
    await sendVisitData({
      projectId,
      baseUrl,
      demoId,
      companyId,
      event: AnalyticsSessionEventValue.LeadCaptured,
      lead: {
        external_user_id: leadData.externalId,
        email: leadData.email,
        ...omit(leadData, ['externalId', 'email']),
      },
    });

    // TODO: prevent lead creation on error

    onLeadCreate(); // shouldn't be invoked if there is `!response.ok`
    return;
  } catch (error) {
    console.error(`Lead analytics network request failed: ${error}`);
  }
};

interface CreateCrossOriginEventBaseArg {
  event: CrossOriginSessionEventEvent;
  demoId: string;
  demoUrl: string;
  demoName: string;
}
interface CreateCrossOriginInitEventArg extends CreateCrossOriginEventBaseArg {
  event: CrossOriginSessionEventEvent.ProjectResolved;
  demoId: string;
  demoUrl: string;
}
interface CreateCrossOriginLeadEventArg extends CreateCrossOriginEventBaseArg {
  event: CrossOriginSessionEventEvent.LeadCaptured;
  demoId: string;
  demoUrl: string;
  lead: PublicDemoLead | null;
}
interface CreateCrossOriginPageEventArg extends CreateCrossOriginEventBaseArg {
  event: CrossOriginSessionEventEvent.PageChanged;
  demoId: string;
  demoUrl: string;
  page: {
    id: string;
    name: string;
  };
}
interface CreateCrossOriginRecordingStartEventArg
  extends CreateCrossOriginEventBaseArg {
  event: CrossOriginSessionEventEvent.RecordingStart;
  demoId: string;
  demoUrl: string;
  page: {
    id: string;
    name: string;
  };
}
interface CreateCrossOriginRecordingStopEventArg
  extends CreateCrossOriginEventBaseArg {
  event: CrossOriginSessionEventEvent.RecordingStop;
  demoId: string;
  demoUrl: string;
}
interface CreateCrossOriginWidgetEventArg
  extends CreateCrossOriginEventBaseArg {
  event: CrossOriginSessionEventEvent.WidgetChanged;
  demoId: string;
  demoUrl: string;
  flow: {
    id: string;
    name: string;
  };
  widget: {
    id: string;
    index: number;
  };
}
interface CreateCrossOriginWidgetReadyEventArg
  extends CreateCrossOriginEventBaseArg {
  event: CrossOriginSessionEventEvent.WidgetReady;
  demoId: string;
  demoUrl: string;
  flow: {
    id: string;
    name: string;
  };
  widget: {
    id: string;
    index: number;
  };
}
interface CreateCrossOriginWidgetCtaClickEventArg
  extends CreateCrossOriginEventBaseArg {
  event: CrossOriginSessionEventEvent.WidgetCtaClick;
  demoId: string;
  demoUrl: string;
  flow: {
    id: string;
    name: string;
  };
  widget: {
    id: string;
    index: number;
  };
}

interface CreateCrossOriginWidgetSecondaryClickEventArg
  extends CreateCrossOriginEventBaseArg {
  event: CrossOriginSessionEventEvent.WidgetSecondaryClick;
  demoId: string;
  demoUrl: string;
  flow?: {
    id: string;
    name: string;
  };
  widget?: {
    id: string;
    index: number;
  };
}
interface CreateCrossOriginFlowEventArg extends CreateCrossOriginEventBaseArg {
  event: CrossOriginSessionEventEvent.FlowChanged;
  demoId: string;
  demoUrl: string;
  flow: {
    id: string;
    name: string;
  };
}
interface CreateCrossOriginFlowEndEventArg
  extends CreateCrossOriginEventBaseArg {
  event: CrossOriginSessionEventEvent.FlowEnd;
  demoId: string;
  demoUrl: string;
  flow: {
    id: string;
    name: string;
  };
}
interface CreateCrossOriginExtUrlEventArg
  extends CreateCrossOriginEventBaseArg {
  event: CrossOriginSessionEventEvent.OpenedExternalUrl;
  demoId: string;
  demoUrl: string;
  extUrl: string;
  extUrlTarget: ExtUrlTarget;
  flow?: {
    id: string;
    name: string;
  };
  widget?: {
    id: string;
    index: number;
  };
}
interface CreateCrossOriginFlowlistItemEventArg
  extends CreateCrossOriginEventBaseArg {
  event: CrossOriginSessionEventEvent.FlowlistItemClick;
  demoId: string;
  demoUrl: string;
  flowlistItemId: string;
}
interface CreateCrossOriginDemoFinishedArg
  extends CreateCrossOriginEventBaseArg {
  event: CrossOriginSessionEventEvent.DemoFinished;
}
interface CreateCustomCtaOriginEventArg extends CreateCrossOriginEventBaseArg {
  event: CrossOriginSessionEventEvent.CustomCta;
  widget?: {
    id: string;
    index: number;
  };
  flow?: {
    id: string;
    name: string;
  };
  extUrl: string;
  extUrlTarget: ExtUrlTarget;
}

type CreateCrossOriginEventArg =
  | CreateCrossOriginExtUrlEventArg
  | CreateCrossOriginInitEventArg
  | CreateCrossOriginFlowEndEventArg
  | CreateCrossOriginFlowEventArg
  | CreateCrossOriginLeadEventArg
  | CreateCrossOriginPageEventArg
  | CreateCrossOriginRecordingStartEventArg
  | CreateCrossOriginWidgetEventArg
  | CreateCrossOriginWidgetReadyEventArg
  | CreateCrossOriginWidgetCtaClickEventArg
  | CreateCrossOriginWidgetSecondaryClickEventArg
  | CreateCrossOriginFlowlistItemEventArg
  | CreateCrossOriginDemoFinishedArg
  | CreateCustomCtaOriginEventArg
  | CreateCrossOriginRecordingStopEventArg;

export function createCrossOriginEventMessage({
  demoId,
  demoUrl,
  demoName,
  ...restArg
}: CreateCrossOriginEventArg): {
  message: string;
  payload: CrossOriginSessionEvent;
} {
  let flow = null;
  let lead = null;
  let page = null;
  let step = null;
  let target = null;
  let checklistItem = null;
  if (restArg.event === CrossOriginSessionEventEvent.LeadCaptured) {
    lead = restArg.lead;
  }

  if (
    (restArg.event === CrossOriginSessionEventEvent.FlowChanged ||
      restArg.event === CrossOriginSessionEventEvent.WidgetChanged ||
      restArg.event === CrossOriginSessionEventEvent.FlowEnd ||
      restArg.event === CrossOriginSessionEventEvent.OpenedExternalUrl ||
      restArg.event === CrossOriginSessionEventEvent.WidgetCtaClick ||
      restArg.event === CrossOriginSessionEventEvent.WidgetSecondaryClick ||
      restArg.event === CrossOriginSessionEventEvent.CustomCta ||
      restArg.event === CrossOriginSessionEventEvent.WidgetReady) &&
    restArg.flow
  ) {
    flow = restArg.flow;
  }

  if (
    (restArg.event === CrossOriginSessionEventEvent.WidgetChanged ||
      restArg.event === CrossOriginSessionEventEvent.OpenedExternalUrl ||
      restArg.event === CrossOriginSessionEventEvent.WidgetCtaClick ||
      restArg.event === CrossOriginSessionEventEvent.WidgetSecondaryClick ||
      restArg.event === CrossOriginSessionEventEvent.CustomCta ||
      restArg.event === CrossOriginSessionEventEvent.WidgetReady) &&
    restArg.widget
  ) {
    step = { ...restArg.widget, index: restArg.widget.index + 1 };
  }

  if (
    restArg.event === CrossOriginSessionEventEvent.PageChanged ||
    restArg.event === CrossOriginSessionEventEvent.RecordingStart
  ) {
    page = restArg.page;
  }

  if (
    restArg.event === CrossOriginSessionEventEvent.OpenedExternalUrl ||
    restArg.event === CrossOriginSessionEventEvent.CustomCta
  ) {
    target = { url: restArg.extUrl, target: restArg.extUrlTarget };
  }

  if (restArg.event === CrossOriginSessionEventEvent.FlowlistItemClick) {
    checklistItem = { id: restArg.flowlistItemId };
  }

  return {
    message: CrossOriginSessionEventMessage,
    payload: {
      event: restArg.event,
      demo: {
        id: demoId,
        url: demoUrl,
        name: demoName,
      },
      flow,
      page,
      lead,
      step,
      target,
      checklist_item: checklistItem,
    },
  };
}

export function sendGAEvent(
  arg: ThirdPartyAnalyticsEventReturn<ThirdPartyAnalyticsEventPayload>
) {
  // @ts-expect-error ok add global
  window.gtag('event', arg.name, arg.payload);
}

export interface ThirdPartyAnalyticsEventReturn<
  P extends unknown = ThirdPartyAnalyticsEventPayload
> {
  name: string;
  payload: P;
}
type ThirdPartyAnalyticsEventPayload = Record<
  string,
  string | number | boolean
>;
export function sendVendorAnalyticsEventCreator<
  P extends ThirdPartyAnalyticsEventPayload
>(
  eventName: ThirdPartyAnalyticsEvent
): (payload: P) => ThirdPartyAnalyticsEventReturn<P> {
  return (payload: P) => ({
    name: eventName,
    payload,
  });
}

export const sendProjectResolvedVACreator = sendVendorAnalyticsEventCreator<{
  demo_id: string;
  demo_url: string;
  demo_name: string;
}>(ThirdPartyAnalyticsEvent.ProjectResolved);
export const sendDemoFinishedVACreator = sendVendorAnalyticsEventCreator<{
  demo_id: string;
  demo_url: string;
  demo_name: string;
}>(ThirdPartyAnalyticsEvent.DemoFinished);
export const sendFlowlistItemClickVACreator = sendVendorAnalyticsEventCreator<{
  demo_id: string;
  demo_url: string;
  demo_name: string;
  flowlist_item_id: string;
}>(ThirdPartyAnalyticsEvent.FlowlistItemClick);
export const sendFlowEndVACreator = sendVendorAnalyticsEventCreator<{
  demo_id: string;
  demo_url: string;
  demo_name: string;
  flow_id: string;
  flow_name: string;
}>(ThirdPartyAnalyticsEvent.FlowEnd);
export const sendPageChangedVACreator = sendVendorAnalyticsEventCreator<{
  demo_url: string;
  demo_id: string;
  demo_name: string;
  page_id: string;
  page_name: string;
}>(ThirdPartyAnalyticsEvent.PageChanged);
export const sendLeadCapturedVACreator = sendVendorAnalyticsEventCreator<{
  demo_id: string;
  demo_url: string;
  demo_name: string;
  [key: string]: string | number | boolean;
}>(ThirdPartyAnalyticsEvent.LeadCaptured);
export const sendFlowChangedVACreator = sendVendorAnalyticsEventCreator<{
  demo_id: string;
  demo_url: string;
  demo_name: string;
  flow_id: string;
  flow_name: string;
}>(ThirdPartyAnalyticsEvent.FlowChanged);
export const sendWidgetChangedVACreator = sendVendorAnalyticsEventCreator<{
  demo_id: string;
  demo_url: string;
  demo_name: string;
  flow_id: string;
  flow_name: string;
  step_id: string;
  step_index: number;
}>(ThirdPartyAnalyticsEvent.WidgetChanged);
export const sendWidgetCTAClickVACreator = sendVendorAnalyticsEventCreator<{
  demo_id: string;
  demo_url: string;
  demo_name: string;
  flow_id: string;
  flow_name: string;
  step_id: string;
  step_index: number;
}>(ThirdPartyAnalyticsEvent.WidgetCtaClick);
export const sendWidgetSecondaryClickVACreator =
  sendVendorAnalyticsEventCreator<{
    demo_id: string;
    demo_url: string;
    demo_name: string;
    flow_id: string;
    flow_name: string;
    step_id: string;
    step_index: number;
  }>(ThirdPartyAnalyticsEvent.WidgetSecondaryClick);
export const sendOpenedExternalUrlVACreator = sendVendorAnalyticsEventCreator<{
  demo_id: string;
  demo_url: string;
  demo_name: string;
  step_id?: string;
  step_index?: number;
  flow_id?: string;
  flow_name?: string;
  ext_url: string;
  ext_url_target: ExtUrlTarget;
}>(ThirdPartyAnalyticsEvent.OpenedExternalUrl);
export const sendCustomCtaVACreator = sendVendorAnalyticsEventCreator<{
  demo_id: string;
  demo_url: string;
  demo_name: string;
  step_id?: string;
  step_index?: number;
  flow_id?: string;
  flow_name?: string;
  ext_url: string;
  ext_url_target: ExtUrlTarget;
}>(ThirdPartyAnalyticsEvent.CustomCta);
