const cookieStorage: Record<string, string> = {};

export const setCookie = (key: string, value: string): void => {
  cookieStorage[key] = value;
};

export const getCookie = (key: string): string | undefined =>
  cookieStorage[key];

export const removeCookie = (key: string): void => {
  delete cookieStorage[key];
};

export const clearCookies = (): void => {
  Object.keys(cookieStorage).forEach((key) => {
    delete cookieStorage[key];
  });
};

export default {
  setItem: setCookie,
  getItem: getCookie,
  removeItem: removeCookie,
  clear: clearCookies,
};
