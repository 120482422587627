import memoryStorage from './memoryStorage';

const isSessionStorageAvailable = () => {
  try {
    window.sessionStorage.setItem('a', '1');
    window.sessionStorage.removeItem('a');
    return true;
  } catch (e) {
    return false;
  }
};

const storage = isSessionStorageAvailable()
  ? window.sessionStorage
  : memoryStorage;

const setItem = (key: string, value: string): void => {
  storage.setItem(key, value);
};

const getItem = (key: string): string | null => storage.getItem(key);

const removeItem = (key: string): void => storage.removeItem(key);

const clear = (): void => storage.clear();

export default {
  setItem,
  getItem,
  removeItem,
  clear,
};
