import { parse } from 'cookie';

import { AnalyticsSessionEventValue } from '../PublicDemoPage.constants';
import { PublicDemoLead } from '../PublicDemoPage.types';

export interface PostSharedProjectEventsParams {
  projectId: string;
  body: {
    demo: {
      fingerprint: string;
      permanent_cookie_id: string;
      current_cookie_id: string;
      event: AnalyticsSessionEventValue;
      view_options: {
        hide_all?: boolean;
        hide_steps?: boolean;
        hide_forms?: boolean;
      };
      event_options: {
        extUrl?: string;
      };
      target_id?: string;
      user_id?: string;
      client_tracking?: Record<string, string>;
      lead?: PublicDemoLead;
      hub_cookie_id?: string;
    };
    link_value: string;
    host_url?: string;
    url_query?: string | null;
    lead_info?: PublicDemoLead | null;
    sl_token?: string;
  };
  baseUrl: string;
}

export const postSharedProjectEvents = ({
  projectId,
  baseUrl,
  body,
}: PostSharedProjectEventsParams) => {
  const { token } = parse(document.cookie) as { token: string };
  return fetch(`${baseUrl}shared/projects/${projectId}/events`, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }).catch((error) => {
    console.error(`Analytics network request failed: ${error}`);
  });
};
