const storage: Record<string, string> = {};

const setItem = (key: string, value: string): void => {
  storage[key] = value;
};

const getItem = (key: string): string | null => storage[key];

const removeItem = (key: string): void => {
  delete storage[key];
};
const clear = (): void => {
  for (const prop of Object.getOwnPropertyNames(storage)) {
    delete storage[prop];
  }
};

export default {
  getItem,
  setItem,
  removeItem,
  clear,
};
