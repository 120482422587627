import { CrossOriginSessionEventEvent } from './PublicDemoPage.constants';

export const enum PublicDemoLeadSource {
  InternalForm = 'internal_form',
  VendorForm = 'vendor_form',
  UrlParams = 'url_params',
  RequiredEmail = 'required_email',
}
export type PublicDemoLead = {
  source?: PublicDemoLeadSource;
  email?: string;
  externalId?: string;
} & Partial<Record<string, string>>;

/**
 * @see {@link https://storylane.atlassian.net/l/cp/62zF6du6 Docs}
 */
export interface CrossOriginSessionEvent {
  event: CrossOriginSessionEventEvent;
  demo: {
    id: string;
    url: string;
    name: string;
  };
  page: {
    id: string;
    name: string;
  } | null;
  flow: {
    id: string;
    name: string;
  } | null;
  step: {
    id: string;
    index: number;
  } | null;
  lead: PublicDemoLead | null;
  target: {
    url: string;
    target: ExtUrlTarget;
  } | null;
  checklist_item: {
    id: string;
  } | null;
}

export type ExtUrlTarget = '_blank' | '_self';
